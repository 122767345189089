<div class="multiple-input-search-container">
  <div id="multipleSearchArea" class="custom-multiple-input-search">
    <div id="innerSearchArea">
      <div class="delete-icon">
          <mat-icon
              id="deleteAllTagsButton"
              [matTooltip]="'multipleInputSearchLabels.deleteAllTags' | translate"
              matTooltipPosition='above'
              class="icon-button"
              (click)="deleteItems()">
              delete
          </mat-icon>
      </div>
      <div class="tag-input-div">
        <mat-form-field class="chip-list"  appearance="none">
          <mat-label *ngIf="!tagsInput.length"> {{ 'multipleInputSearchLabels.tagInputSecondaryPlaceholder' | translate }} </mat-label>
            <mat-chip-list
              #chipList
              aria-label="Tags Input">
                <mat-chip
                    *ngFor="let tag of tagsInput"
                    [class]="tag.class"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(tag)">
                    {{ tag.display }}
                  <mat-icon
                    matChipRemove
                    id="removeChip"
                    *ngIf="removable">
                    cancel
                  </mat-icon>
                </mat-chip>
                <input
                    id="chipInput"
                    placeholder="{{ 'multipleInputSearchLabels.tagInputPlaceholder' | translate }}"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (paste)="onPaste($event)"
                    (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
        </mat-form-field>
    </div>
  </div>
</div>
